<template>
    <div @click="viewMyProject">
        <div @click="viewMyProject"
            class="shadow border border-dark rounded project-card-dim text-truncate"
        >
            <div class="row center-horizontal image-trim">
                <img :src="imgUrl" alt="image">
            </div>
            <div class="row align-items-center title-trim">
                <div class="col-12 text-truncate card-title pt-1 px-3">
                    {{project.title}}
                </div>
            </div>
        </div>

        
       
    </div>
</template>

<script>
import { storage } from '../../../db';

export default {
    props: {
        project: null,
        preloadedImg: {type: String, default: null},
    },
    computed: {
        // backgroundImageBuilder: function() {
        //     // concatenate the background-image tag with the supplied url
        //     return "background-image: url('" + this.imgUrl + "');";
        // }
    },  
    mounted() {
        if (this.preloadedImg == null) {
            this.getProjectImg();
            console.log("had to fetch");
        } else {
            this.imgUrl = this.preloadedImg;
            console.log("didn't have to fetch");
        }
    },
    data() {
        return {
            imgUrl: "#"
        }
    },
    methods: {
        viewMyProject() {
            this.$emit("viewMyProject", this.project.id);
        },
        getProjectImg() {
            console.log("Getting project img");
            var ref = storage.ref().child("projects/" + this.project.id + "/main");
            console.log("got ref: " + ref.toString());
            ref.getDownloadURL().then((url) => {
                console.log(url);
                this.imgUrl = url;
            }).catch((err) => console.log("Error getting image for project " + this.project.id + `: ${err}`));
        }
    }
}
</script>

<style scoped>

    .center-text {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .center-horizontal {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card-title {
        font-weight: 600;
        font-size: 1.2rem;
    }

    .cover-fit {
        object-fit: cover;
        width: 30vw;
    }

    .image-trim {
        max-height: 70%;
        overflow: hidden;
    }

    .title-trim {
        min-height: 30%;
    }


</style>