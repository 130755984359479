<template>
    <div>
        <AppbarFixed :hasBackButton="true" title="Saved Projects" />
        <div v-if="loading == true">
            <b-spinner class="mt-5"></b-spinner>
        </div>
        
        <div v-else class="mt-5 mx-3">
            <b-container  v-for="(row, index) in chunkedProjects" v-bind:key="index">
                <b-row>
                    <b-col>
                        <ProjectCard :project="row[0]" @viewMyProject="viewProject"/>
                    </b-col>
                    <b-col>
                        <ProjectCard :project="row[1]" @viewMyProject="viewProject"/>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div v-if="projects.length == 0 && loading == false" class="mt-5">
            <h2>No Saved Projects</h2>
        </div>
        <div class="bottom-margin-box"></div>
        <BottomNavBar :currentIndex="1" @updateIndex="updateIndex"/>
    </div>
</template>

<script>
import AppbarFixed from '../../components/general/AppbarFixed.vue';
import ProjectCard from '../../components/profile/nested/MyProjectCard.vue';
import BottomNavBar from '../../components/general/BottomNavBar.vue';
import { db, fieldPath } from '../../db';
import { mapState } from "vuex";

export default {
    data() {
        return {
            projects: [],
            loading: true,
        }
    },
    components: {
        AppbarFixed,
        ProjectCard,
        BottomNavBar,
    },
    mounted() {
        this.loadSavedProjects();
    },
    methods: {
        viewProject(id) {
            this.$router.push('/project/' + id);
        },
        loadSavedProjects() {
            this.loading = true;
            if (this.parsillUser == null) {
                this.loading = false;
                return;
            }
            if (this.parsillUser.savedProjects == null) {
                this.loading = false;
                return;
            }

            if (this.parsillUser.savedProjects.length == 0) {
                this.loading = false;
                return;
            }

            db.collection("projects").where(fieldPath.documentId(), "in", this.parsillUser.savedProjects).get()
            .then((querysnap) => {
                var loadedProjects = [];
                for (var i=0; i < querysnap.docs.length; i++) {
                    var doc = querysnap.docs[i];
                    var project = doc.data();
                    project.id = doc.id;
                    loadedProjects.push(project);
                }
                this.projects = loadedProjects;
                this.loading = false;
            })
            .catch((err) => {
                alert(err);
                this.loading = false;
            });

        },
        updateIndex(index) {
            if (index == 4) {
                // means its create project
                this.$router.push("/create/project");
                index = 1;
            } else {
                this.$router.push("/");
            }
            this.$store.commit("updateTabIndex", index);
        }
    },
    computed: {
        chunkedProjects: function() {
            var chunks = [];

            for (var i=0; i < this.projects.length; i++) {
                if (i % 2 == 0) {
                    // create a new row
                    chunks.push([]);
                }

                chunks[chunks.length-1].push(this.projects[i]);
            }

            console.log(chunks);
            return chunks;
        },
        ...mapState({
            parsillUser: (state) => state.parsillUser,
        }),
    }
}
</script>

<style scoped>

</style>